import React from 'react'

import * as AppUI from '../../app'
import Grid2Columns from '../../layouts/Grid2Columns'
import * as UI from '../styled-components'
import Section from './section'

const tabName = 'MONTHLY_GROSS_INCOME'
const tabLabel = 'Monthly Gross Income'

const MonthlyGrossIncome = props => {
  const {
    toggleActiveTab,
    activeTab,
    party1Label,
    party2Label,
    PARTY_1,
    PARTY_2,
    party1,
    party2,
    dispatch
  } = props
  return (
    <Section {...{tabName, tabLabel, toggleActiveTab, activeTab}}>
      <UI.BodySectionInner>
        <Grid2Columns>
          <AppUI.Input>
            <label>{party1Label}</label>
            <input
              type="number"
              step="0.01"
              min="0"
              value={party1.monthlyGross}
              onChange={e => {
                const newMonthlyGross = '' === e.target.value ? false : Number(e.target.value)
                dispatch({ type: 'SET_PARTY', party: PARTY_1,
                  update: { monthlyGross: newMonthlyGross }
                })
              }}
            />
          </AppUI.Input>
          <AppUI.Input>
            <label>{party2Label}</label>
            <input
              type="number"
              step="0.01"
              min="0"
              value={party2.monthlyGross}
              onChange={e => {
                const newMonthlyGross = '' === e.target.value ? false : Number(e.target.value)
                dispatch({ type: 'SET_PARTY', party: PARTY_2,
                  update: { monthlyGross: newMonthlyGross }
                })
              }}
            />
          </AppUI.Input>
        </Grid2Columns>
      </UI.BodySectionInner>
    </Section>
  )
}
export default MonthlyGrossIncome
