import ChildSupportCalculationsTableData from './helpers/child-support-calculations-table-data'
import SpousalSupportCalculationsTableData from './helpers/spousal-support-calculations-table-data'
import { constructPDFObject } from "./helpers/index";

const exporter = (formatter, method) => data => {
  constructPDFObject().createPdf(formatter(data))[method]()
}

export const downloadChildSupportWorksheet = exporter(ChildSupportCalculationsTableData, 'download')
export const printChildSupportWorksheet = exporter(ChildSupportCalculationsTableData, 'print')
export const previewChildSupportWorksheet = exporter(ChildSupportCalculationsTableData, 'open')
export const previewSpousalSupportWorksheet = exporter(SpousalSupportCalculationsTableData, 'open')
