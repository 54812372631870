import Row from './row'
const MaintenanceBeforeTaxAdjustement = (sectionIndex, data) => Row({
  sectionIndex,
  label: [
          'Calculated Maintenance ',
          { text: 'before', decoration: 'underline' },
          ' Tax Adjustment: Difference between 40% of Combined Monthly Adjusted Growth Income and Monthly Adjusted Gross Income of Party with Lower Income'
        ],
  combined: data.worksheet.combined.maintenanceBeforeTaxAdjustment,
})
export default MaintenanceBeforeTaxAdjustement
