import React from 'react'
import * as AppUI from '../../app'
import { DarkAppButton } from '../../buttons/AppButton'
import Inputs from '../../inputs'
import Forms from '../../forms'
import ModalWrapper from '../../modals/ModalWrapper'

const Export = props => {
  const {
    exportModalVisible,
    exportOptions,
    setExportOption,
    dismissExportModal,
    confirmExport
  } = props
  return <div>
    {exportModalVisible && (
      <ModalWrapper close={dismissExportModal}>
        <Forms.FormGrid2>
          <form onSubmit={confirmExport}>
            <h3 className="AppFormTitle">Confirm Export(s)</h3>
            <AppUI.Input>
              <Inputs.CheckboxLabel htmlFor="exportOptionChildSupport">Export child support
                <input
                  id="exportOptionChildSupport"
                  name="exportOptionChildSupport"
                  type="checkbox"
                  checked={exportOptions.childSupport}
                  onChange={e => setExportOption('childSupport', !exportOptions.childSupport)}
                  />
              </Inputs.CheckboxLabel>
            </AppUI.Input>
            <AppUI.Input>
              <Inputs.CheckboxLabel htmlFor="exportOptionSpousalSupport">Export spousal support
                <input
                  id="exportOptionSpousalSupport"
                  name="exportOptionSpousalSupport"
                  type="checkbox"
                  checked={exportOptions.spousalSupport}
                  onChange={e => setExportOption('spousalSupport', !exportOptions.spousalSupport)}
                  />
              </Inputs.CheckboxLabel>
            </AppUI.Input>
            <br />
            <div className="AppSubmit">
              <DarkAppButton className="dark" type="submit">Export Now</DarkAppButton>
            </div>
          </form>
        </Forms.FormGrid2>
      </ModalWrapper>
    )}
  </div>
}
export default Export
