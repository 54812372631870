import Row from './row'
const MaintenanceAfterTaxAdjustement = (sectionIndex, data) => Row({
  sectionIndex,
  label: [
          'Calculated Maintenance ',
          { text: 'after', decoration: 'underline' },
          ' Tax Adjustment'
        ],
  combined: data.worksheet.combined.maintenanceAfterTaxAdjustment,
})
export default MaintenanceAfterTaxAdjustement
