// TODO: DELETE THIS FILE
export const PDFHeader = data => {
  const worksheetTitle = 'a' === data.caseHeader.workSheetType ?  'CHILD SUPPORT WORKSHEET A'
    : 'b' === data.caseHeader.workSheetType ? 'CHILD SUPPORT WORKSHEET B'
    : data.caseHeader.workSheetType
  return {
    table: {
      widths: ['*', '*', '*', '*', '*', '*'],
      body: [
        [{
          //text: data.caseHeader.courtType + ' of ' + data.caseHeader.courtCounty + '\n\n' + data.caseHeader.courtAddress + '\n\n',
          text: data.caseHeader.courtName + '\n\n',
          colSpan: 3
        }, {}, {}, {
          border: [true, true, true, true],
          text: '▲ COURT USE ONLY ▲',
          rowSpan: 2,
          margin: [0, 116, 0, 0],
          colSpan: 3,
          alignment: 'center'
        }, {}, {}],
        [{
          text: data.caseHeader.caseType + ':\n\n '
          + 'Petitioner: ' + data.caseHeader.party1Name + '\n\n'
          + 'and\n\n'
          + data.caseHeader.party2Type + ': ' + data.caseHeader.party2Name,
          colSpan: 3
        }, {}, {}, {}, {}, {}],
        [{
          text: 'Attorney or Filing Party:\n' +
            data.caseHeader.filingPartyDesignation + '\n' +
            data.caseHeader.filingPartyName + '\n' +
            (data.caseHeader.filingPartyFirmName ? data.caseHeader.filingPartyFirmName + '\n' : '' ) +
            data.caseHeader.filingPartyAddress1 + '\n' +
            (data.caseHeader.filingPartyAddress2 ? data.caseHeader.filingPartyAddress2 + '\n' : '') +
            (data.caseHeader.cityStateZip ? data.caseHeader.cityStateZip + '\n' : '') +
            'Phone: ' + data.caseHeader.filingPartyPhone + '\n' +
            'FAX: ' + data.caseHeader.filingPartyFax + '\n' +
            'Email: ' + data.caseHeader.filingPartyEmail + '\n' +
            'Atty. Reg. #: ' + data.caseHeader.filingPartyAttyReg + '\n',
          colSpan: 3
        }, {}, {}, {
          text: '\n\nCase Number: ' + data.caseHeader.caseNumber1 + '\nAdditional Case Number: ' + data.caseHeader.caseNumber2 + '\n\nDivision/Courtroom: ' + data.caseHeader.divisionCourtroom,
          colSpan: 3
        }, {}, {}],
        [{
          text: worksheetTitle,
          alignment: 'center',
          margin: 10,
          colSpan: 6,
          style: {
            bold: true
          }
        }, {}, {}, {}, {}, {}]
      ]
    },
    layout: {
      hLineColor: '#101010',
      vLineColor: '#101010',
      hLineWidth: () => 1
    }
  };
};
