import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { CloseButton } from '../buttons/AppButton'
import GridCenter from '../layouts/GridCenter'

const ModalWrapper = props => {
  return (
    <StyledModalWrapper>
      <GridCenter>
        <Inner>
          <CloseButton onClick={props.close}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>
          {props.children}
        </Inner>
      </GridCenter>
    </StyledModalWrapper>
  )
}

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to right, rgba(0,0,0,0.85), rgba(0,0,0,0.75), rgba(0,0,0,0.85));
  background: linear-gradient(to bottom, rgba(0,0,0,0.95), rgba(0,0,0,0.25), rgba(0,0,0,0.99));
  z-index: 999999;

  /*
  // other modal background ideas:
  background-color: rgba(255,255,255,0.95);
  background-color: rgba(0,0,0,0.7);
  background: linear-gradient(to bottom right, #EE7A30, #EA368A, #7A2CF5);
  background: linear-gradient(to bottom right, rgba(137,37,244,0.35), rgba(252,119,41,0.35));
  background: linear-gradient(to bottom right, rgba(137,37,244,0.75), rgba(252,119,41,0.75));
  background-color: rgba(0,0,0,0.75);
  */
`
const Inner = styled.div`
  width: 80vw;
  min-height: 50px;
  max-height: 80vh;
  padding: 25px;
  margin: 25px auto 25px;
  background-color: white;
  box-shadow: 0px 0px 25px #999;
  box-shadow: 0px 0px 25px #000;
  overflow: scroll;
  animation: ${props => props.theme.keyframes.fadeInSlideUp} ${props => props.theme.speeds.med} ease-out;
`

export default ModalWrapper
