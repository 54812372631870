const Row = ({sectionIndex, label, party1, party2, combined}) => [
  [
    {
      ol: [{
        text: label,
      }],
      start: sectionIndex ? sectionIndex : 1,
      colSpan: 3,
      style: {
        bold: true
      }
    },
    {},
    {},
    {},
    {},
    {
      text: combined,
      rowSpan: 1
    }
  ]
]
export default Row
