import { PDFCalculationsTableLayout, PDFFormSections } from './index';
import FortyPercentCombinedMAGI from './rows/forty-percent-combined-magi'
import LowerAdjustedGrossIncome from './rows/lower-adjusted-gross-income'
import MaintenanceBeforeTaxAdjustement from './rows/maintenance-before-tax-adjustment'
import MaintenanceAfterTaxAdjustement from './rows/maintenance-after-tax-adjustment'
import LengthOfMarriage from './rows/length-of-marriage'
import TermOfMaintenance from './rows/term-of-maintenance'

export default function SpousalSupportTableBody (data) {
  let tableLayout = PDFCalculationsTableLayout(data)
  let tableOneContent = [];

  tableOneContent.push.apply(tableOneContent, PDFFormSections('monthly-gross-income', 1, data));
  tableOneContent.push.apply(tableOneContent, PDFFormSections('monthly-adjusted-gross-income', 2, data));
  tableOneContent.push.apply(tableOneContent, FortyPercentCombinedMAGI(3, data));
  tableOneContent.push.apply(tableOneContent, LowerAdjustedGrossIncome(4, data));
  tableOneContent.push.apply(tableOneContent, MaintenanceBeforeTaxAdjustement(5, data));
  tableOneContent.push.apply(tableOneContent, MaintenanceAfterTaxAdjustement(6, data));
  tableOneContent.push.apply(tableOneContent, LengthOfMarriage(7, data));
  tableOneContent.push.apply(tableOneContent, TermOfMaintenance(8, data));
  tableOneContent.push.apply(tableOneContent, PDFFormSections('comments', null, data));
  tableOneContent.push.apply(tableOneContent, PDFFormSections('signature', null, data));

  tableLayout.table.body.push.apply(tableLayout.table.body, tableOneContent);

  return tableLayout
}
