import { PDFCalculationsTableLayout, PDFFormSections } from './index';

export default function ChildSupportTableBody (data) {
  let calculationsTables = [
    PDFCalculationsTableLayout(data),
    PDFCalculationsTableLayout(data)
  ];
  let tableOneContent = [];
  let tableTwoContent = [];

  // Construct First Page Calculations Table

  tableOneContent.push.apply(tableOneContent, PDFFormSections('monthly-gross-income', 1, data));
  tableOneContent.push.apply(tableOneContent, PDFFormSections('monthly-adjusted-gross-income', 2, data));

  calculationsTables[0].table.body.push.apply(calculationsTables[0].table.body, tableOneContent);

  // Construct Second Page Calculations Table

  calculationsTables[1].pageBreak = 'before';

  tableTwoContent.push.apply(tableTwoContent, PDFFormSections('percentage-share-of-income', 3, data));

  if (data.caseHeader.workSheetType === 'a') {
    // Worksheet A Specific Content
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('basic-support-obligation', 4, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('low-income-adjustment', 5, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('adjustments', 6, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('total-adjustments', 7, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('each-parents-share-of-adjustments', 8, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('each-parents-share-of-total-support', 9, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('paying-parents-adjustment', 10, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('recomended-child-support-order', 11, data));
  } else {
    // Worksheet B Specific Content
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('basic-support-obligation-short', 4, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('shared-physical-care-support-obligation', 5, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('each-parents-share-of-basic-obligation', 6, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('overnights-with-each-parent', 7, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('percentage-time-with-each-parent', 8, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('support-obligation-for-time-with-other-parents', 9, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('adjustments', 10, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('total-adjustments', 11, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('each-parents-share-of-adjustments', 12, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('paid-in-excess-of-fair-share', 13, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('each-parents-adjusted-support-obligation', 14, data));
    tableTwoContent.push.apply(tableTwoContent, PDFFormSections('recomended-child-support-order', 15, data));

  }

  tableTwoContent.push.apply(tableTwoContent, PDFFormSections('comments', null, data));
  tableTwoContent.push.apply(tableTwoContent, PDFFormSections('signature', null, data));

  calculationsTables[1].table.body.push.apply(calculationsTables[1].table.body, tableTwoContent);


  return calculationsTables;
}
