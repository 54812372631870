import { pdfLayout, PDFHeader, PDFLineBreak } from './index';
import SpousalSupportTableBody from './spousal-support-table-body'

export default function SpousalSupportCalculationsTableData (data) {

  let pdf = pdfLayout();

  // Constructing the header of the worksheet
  pdf.content.push(PDFHeader(data));
  pdf.content.push(PDFLineBreak);

  pdf.content = pdf.content.concat(SpousalSupportTableBody(data));

  return pdf;
};
