const PARTY_1 = 'PARTY_1'
const PARTY_2 = 'PARTY_2'

export const PDFCalculationsTableLayout = (data) => {
  return {
    layout: 'myTableLayout',
    table: {
      headerRows: 1,
      dontBreakRows: true,
      widths: ['*', '*', '*', '*', '*', '*'],
      body: [
        [{
          text: ' ',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet[PARTY_1].label,
          style: {
            bold: true
          }
        }, {
          text: data.worksheet[PARTY_2].label,
          style: {
            bold: true
          }
        }, {
          text: 'Combined',
          style: {
            bold: true
          }
        }]
      ]
    }
  };
};
