import React, { useState, useReducer } from 'react'
import * as Layout from '../layouts/ContentWithSidebarLeft'
import { presentDollarAmount, presentDecimalAsPercentage } from '../../helpers/presenters'
import AppButton from '../buttons/AppButton'

const PARTY_1 = 'PARTY_1'
const PARTY_2 = 'PARTY_2'
const {
    MainCol1,
    AsideActions,
    AsideTitle,
    AsideTitleHighlight,
    AsideSticky,
    AsideSection,
    AsideSummary,
    SummaryLabel,
    SummaryValue,
    SummaryValueHighlight
} = Layout

const SummaryPanel = props => {

    const {
      numberOfChildren,
      party1,
      party2,
      party1Label,
      overnightsParty1,
      party2Label,
      overnightsParty2,
      spousalSupport,
      party1MonthlyAdjustedGross,
      party2MonthlyAdjustedGross,
      party1ShareOfIncome,
      party2ShareOfIncome,
      payor,
      recommendedChildSupportOrder,
      netPayor,
      netAmount,
      state,
      caseProfile,
      attorneyProfiles,
      updateCaseDoc,
      handleExport,
      handleSave
    } = props
    return (
        <MainCol1>
            <AsideSticky>
                <AsideSection>
                    <AsideSummary>
                        <SummaryLabel><strong>Children</strong></SummaryLabel>
                        <SummaryValue>{numberOfChildren}</SummaryValue>
                    </AsideSummary>
                </AsideSection>

                <AsideSection>
                    <AsideTitle><strong>Overnights</strong></AsideTitle>
                    <AsideSummary>
                        <SummaryLabel>{party1Label}</SummaryLabel>
                        <SummaryValue>{overnightsParty1}</SummaryValue>
                    </AsideSummary>
                    <AsideSummary>
                        <SummaryLabel>{party2Label}</SummaryLabel>
                        <SummaryValue>{overnightsParty2}</SummaryValue>
                    </AsideSummary>
                </AsideSection>

                <AsideSection>
                    <AsideTitle><strong>Gross Income</strong></AsideTitle>
                    <AsideSummary>
                        <SummaryLabel>{party1Label}</SummaryLabel>
                        <SummaryValue>
                        {party1.monthlyGross && presentDollarAmount(party1.monthlyGross)}
                        </SummaryValue>
                    </AsideSummary>
                    <AsideSummary>
                        <SummaryLabel>{party2Label}</SummaryLabel>
                        <SummaryValue>
                        {party2.monthlyGross && presentDollarAmount(party2.monthlyGross)}
                        </SummaryValue>
                    </AsideSummary>
                </AsideSection>

                {!!spousalSupport.payor &&
                    spousalSupport.maintenance > 0 && (
                    <AsideSection>
                        <AsideTitle><strong>Spousal Maintenance</strong></AsideTitle>
                        <AsideSummary>
                            {spousalSupport.payor === PARTY_1 && <SummaryLabel><em>{party1Label}</em> pays <em>{party2Label}</em></SummaryLabel>}
                            {spousalSupport.payor === PARTY_2 && <SummaryLabel><em>{party2Label}</em> pays <em>{party1Label}</em></SummaryLabel>}
                            <SummaryValue>{presentDollarAmount(spousalSupport.maintenance)}</SummaryValue>
                        </AsideSummary>
                    </AsideSection>
                )}
                {0 === spousalSupport.maintenance  && (
                  <AsideSection>
                      <AsideSummary>
                        <SummaryLabel><strong>Spousal Maintenance</strong></SummaryLabel>
                        <SummaryValue>{presentDollarAmount(spousalSupport.maintenance)}</SummaryValue>
                      </AsideSummary>
                  </AsideSection>
                )}

                <AsideSection>
                    <AsideTitle><strong>Adjusted Gross Income</strong></AsideTitle>
                    <AsideSummary>
                        <SummaryLabel>{party1Label}</SummaryLabel>
                        <SummaryValue>
                        {party1MonthlyAdjustedGross && presentDollarAmount(party1MonthlyAdjustedGross)}
                        </SummaryValue>
                    </AsideSummary>
                    <AsideSummary>
                        <SummaryLabel>{party2Label}</SummaryLabel>
                        <SummaryValue>
                        {party2MonthlyAdjustedGross && presentDollarAmount(party2MonthlyAdjustedGross)}
                        </SummaryValue>
                    </AsideSummary>
                </AsideSection>

                <AsideSection>
                    <AsideTitle><strong>Share of Adjusted Gross Income</strong></AsideTitle>
                    <AsideSummary>
                        <SummaryLabel>{party1Label}</SummaryLabel>
                        <SummaryValue>
                        {presentDecimalAsPercentage(party1ShareOfIncome, 1)}
                        </SummaryValue>
                    </AsideSummary>
                    <AsideSummary>
                        <SummaryLabel>{party2Label}</SummaryLabel>
                        <SummaryValue>
                        {presentDecimalAsPercentage(party2ShareOfIncome, 1)}
                        </SummaryValue>
                    </AsideSummary>
                </AsideSection>

                {false === netPayor && (
                    <AsideSection>
                        <AsideTitleHighlight>
                            <strong>Child Support</strong>
                        </AsideTitleHighlight>
                        {payor === PARTY_1 &&
                            <AsideSummary>
                                <SummaryLabel><em>{party1Label}</em> pays <em>{party2Label}</em></SummaryLabel>
                                <SummaryValueHighlight><strong>{false !== recommendedChildSupportOrder[PARTY_1] && presentDollarAmount(recommendedChildSupportOrder[PARTY_1])}</strong></SummaryValueHighlight>
                            </AsideSummary>
                        }
                        {payor === PARTY_2 &&
                            <AsideSummary>
                                <SummaryLabel><em>{party2Label}</em> pays <em>{party1Label}</em></SummaryLabel>
                                <SummaryValueHighlight><strong>{false !== recommendedChildSupportOrder[PARTY_2] && presentDollarAmount(recommendedChildSupportOrder[PARTY_2])}</strong></SummaryValueHighlight>
                            </AsideSummary>
                        }
                    </AsideSection>
                )}
                
                {false !== netPayor && (
                    <AsideSection>
                        <AsideTitleHighlight>
                            <strong>Net Support</strong>
                        </AsideTitleHighlight>
                        <AsideSummary>
                            <SummaryLabel>Child Support</SummaryLabel>
                            {false !== recommendedChildSupportOrder[PARTY_1] && <SummaryValue>{presentDollarAmount(recommendedChildSupportOrder[PARTY_1])}</SummaryValue>}
                            {false !== recommendedChildSupportOrder[PARTY_2] && <SummaryValue>{presentDollarAmount(recommendedChildSupportOrder[PARTY_2])}</SummaryValue>}
                        </AsideSummary>
                        <AsideSummary>
                            <SummaryLabel>Spousal Maintenance</SummaryLabel>
                            <SummaryValue>{payor === spousalSupport.payor ? "+":"-"}{false !== spousalSupport.maintenance && presentDollarAmount(spousalSupport.maintenance)}</SummaryValue>
                        </AsideSummary>
                        <AsideSummary>
                            {netPayor === PARTY_2 && <SummaryLabel><em>{party2Label}</em> pays <em>{party1Label}</em></SummaryLabel>}
                            {netPayor === PARTY_1 && <SummaryLabel><em>{party1Label}</em> pays <em>{party2Label}</em></SummaryLabel>}
                            <SummaryValueHighlight><strong>{false !== netAmount && presentDollarAmount(netAmount)}</strong></SummaryValueHighlight>
                        </AsideSummary>
                    </AsideSection>
                )}


                <AsideActions>
                    <AppButton onClick={handleExport}>Export</AppButton>
                    <AppButton onClick={handleSave}>Save</AppButton>
                    </AsideActions>

            </AsideSticky>
        </MainCol1>
    )
}

export default SummaryPanel
