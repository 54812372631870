import { pdfLayout, PDFHeader, PDFLineBreak, PDFChildrenTable } from './index';
import ChildSupportTableBody from './child-support-table-body';

export default function ChildSupportCalculationsTableData (data) {

  let pdf = pdfLayout();

  // Constructing the header of the worksheet
  pdf.content.push(PDFHeader(data));

  // Adding a line break between the header, and child list.
  pdf.content.push(PDFLineBreak);

  pdf.content.push(PDFChildrenTable(data));
  pdf.content.push(PDFLineBreak);

  pdf.content = pdf.content.concat(ChildSupportTableBody(data));

  return pdf;
};
